import { Component, inject, signal } from '@angular/core';
import { IAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { SKWInventoryLine } from '../../models/storekeeper-window.model';
import { SKWTranslatePipe } from '../../pipes/storekeeper-window-translate.pipe';
import { SKWContextService } from '../../services/storekeeper-window-context.service';
import { SKWMessageData, SKWMessageService, SKWMessageType } from '../../services/storekeeper-window-message.service';
import { StorekeeperNavbarButtonComponent } from '../storekeeper-navbar/storekeeper-navbar-button/storekeeper-navbar-button.component';
import { StorekeeperToolbarComponent } from '../storekeeper-toolbar/storekeeper-toolbar.component';
import { StorekeeperWindowInputScanComponent } from '../storekeeper-window-input-scan/storekeeper-window-input-scan.component';
import { StorekeeperInventoryLineComponent } from './storekeeper-inventory-line/storekeeper-inventory-line.component';

@Component({
  selector: 'iu-storekeeper-inventory',
  standalone: true,
  imports: [
    StorekeeperToolbarComponent,
    StorekeeperNavbarButtonComponent,
    StorekeeperInventoryLineComponent,
    StorekeeperWindowInputScanComponent,
    SKWTranslatePipe
  ],
  templateUrl: './storekeeper-inventory.component.html',
  styleUrl: './storekeeper-inventory.component.scss'
})
export class StorekeeperInventoryComponent {
  // TODO: add modal for save
  #SKWService = inject(SKWContextService);
  #SKWMessageService = inject(SKWMessageService);

  lines = signal<SKWInventoryLine[]>([]);
  currentLine = signal<Partial<SKWInventoryLine>>({
    M_Locator_ID: undefined,
    M_Product_ID: undefined,
    Quantity: null
  });

  lastEmplacement: IAutocomplete = undefined;

  changeLine(index: number, newValue: Partial<SKWInventoryLine>) {
    this.lines.update((lines) => {
      lines[index] = { ...lines[index], ...newValue };
      return [...lines];
    });
  }

  changeCurrentLine({ type, data }: { type: string; data: IAutocomplete }) {
    if (type === 'M_Product_ID') {
      if (
        this.currentLine()?.M_Product_ID !== undefined &&
        data.id !== this.currentLine()?.M_Product_ID?.id &&
        this.currentLine()?.M_Locator_ID !== undefined &&
        this.currentLine()?.Quantity !== undefined
      ) {
        this.addCurrentLine();
      } else if (this.currentLine()?.M_Product_ID !== undefined && data.id === this.currentLine()?.M_Product_ID?.id) {
        this.currentLine.update((curr) => ({ ...curr, Quantity: (curr.Quantity ?? 0) + 1 }));
        return;
      }
    } else if (type === 'M_Locator_ID' && data?.id !== this.lastEmplacement?.id) {
      this.lastEmplacement = data;
    }

    this.currentLine.update((curr) => ({ ...curr, [type]: data }));
  }

  changeCurrentLineQty(value: SKWInventoryLine) {
    this.currentLine.update((curr) => ({ ...curr, ...value }));
  }

  addCurrentLine() {
    const newLine = { ...this.currentLine() };

    if (!this.#isCurrentLineCorrect(newLine)) {
      return;
    }

    this.lines.update((lines) => [...lines, <SKWInventoryLine>newLine]);

    this.resetCurrentLine();
  }

  #isCurrentLineCorrect(current: Partial<SKWInventoryLine>) {
    let message: SKWMessageData = {
      type: SKWMessageType.ERROR,
      title: 'MissingData',
      key: 'missing_data_error',
      content: ''
    };
    let correct = true;

    if (!current?.Quantity) {
      message.content = 'MissingDataNoQty';
      correct = false;
    }

    if (!current?.M_Locator_ID) {
      message.content = 'MissingDataNoLocator';
      correct = false;
    }

    if (!current?.M_Product_ID) {
      message.content = 'MissingDataNoProduct';
      correct = false;
    }

    if (!correct) {
      this.#SKWMessageService.addMessage(message);
    }

    return correct;
  }

  resetCurrentLine() {
    this.currentLine.set({ M_Locator_ID: this.lastEmplacement });
  }

  removeLine(index: number, event: MouseEvent) {
    this.lines.update((lines) => {
      lines.splice(index, 1);
      return [...lines];
    });
  }

  save(event?: MouseEvent) {
    //TODO: add warning if current line has been modified and not added
    this.#SKWService.saveInventory(this.lines());
  }
}
